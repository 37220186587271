import { loadStripe } from "@stripe/stripe-js";
const isDevelopment = process.env.NODE_ENV === "development";
const betMagicStripeBackendUrl = isDevelopment ? "http://localhost:8000" : "https://bmlpayments.com";
const stripeKey = isDevelopment ? process.env.REACT_APP_STRIPE_DEV_PUBLISHABLE_KEY : process.env.REACT_APP_STRIPE_PROD_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripeKey);

const redirectUserToAccountManagement = (stripeId) => {
  fetch(`${betMagicStripeBackendUrl}/api/v1/create-customer-portal-session`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ stripeId: stripeId }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      window.location.href = data.url;
    });
};

async function createSubscription(paymentMethodId, email, firstName, lastName) {
  try {
    const response = await fetch(`${betMagicStripeBackendUrl}/api/create-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        paymentMethodId,
        email,
        firstName,
        lastName,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error.message || "Subscription creation failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating subscription:", error);
    throw error;
  }
}

export { stripePromise, betMagicStripeBackendUrl, redirectUserToAccountManagement, createSubscription };
