import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";

export function IsLoggedIn({ children }) {
  const { isLoggedIn, isAccountActive } = useContext(UserContext);
  const location = useLocation();

  if (isLoggedIn === true) {
    if (isAccountActive === true) {
      // User is signed in and has paid their monthly fee
      return <Navigate to="/dashboard/index" />;
    } else {
      // User is signed in but has not paid their monthly fee
      // If user is on the registration page, allow them to stay
      if (location.pathname === "/auth/register") {
        return children;
      } else {
        // Optionally, redirect to a page that informs the user to complete payment
        // For now, allow them to stay on the current page
        return children;
      }
    }
  } else {
    return children;
  }
}
