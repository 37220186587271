import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import DashboardLayout from "./layouts/Dashboard.js";
import AuthLayout from "./layouts/Auth.js";
import "./firebase/config.js";
import { UserProvider } from "./context/UserContext";
import { RequireAuth } from "./helpers/RequireAuth";
import { IsLoggedIn } from "./helpers/IsLoggedIn";

import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "./stripe/config";

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <Routes>
        <Route
          path="/dashboard/*"
          element={
            <UserProvider>
              <RequireAuth>
                <DashboardLayout />
              </RequireAuth>
            </UserProvider>
          }
        />
        <Route
          path="/auth/*"
          element={
            <UserProvider>
              <IsLoggedIn>
                <AuthLayout />
              </IsLoggedIn>
            </UserProvider>
          }
        />
        <Route path="/" element={<Navigate to="/auth/login" />} />
      </Routes>
    </Elements>
  );
};

export default App;
